import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './ITManagedServices.module.scss';
import Advantages from '../Advantages';
import ExpandingCard from '../ExpandingCard';
import Feed from '../Feed';
import LeaveMessageBanner from '../LeaveMessageBanner';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import {
  IT_MANAGED_SERVICES_ADVANTAGES_CONTENT, IT_MANAGED_SERVICES_HEADING, IT_MANAGED_SERVICES_INSIGHTS_HEADING,
  IT_MANAGED_SERVICES_PROVIDER_CONTENT, IT_MANAGED_SERVICES_PROVIDER_HEADING, IT_MANAGED_SERVICES_PROVIDER_SUBHEADING,
  IT_MANAGED_SERVICES_SUBHEADING
} from '../../constants/services-pages';

const ITManagedServices = () => {
  const {background, icons, insightsPosts} = useStaticQuery(graphql`
    query {
      background: allFile(
        filter: {
          sourceInstanceName: { eq: "ITManagedServices" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      icons: allFile(
        filter: {
          sourceInstanceName: { eq: "ITManagedServices" }
          extension: { eq: "svg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      insightsPosts: allMarkdownRemark(
        filter: {
          fields: {
            slug: {
              in: ["/posts/10-reasons-why-it-outsourcing-in-ukraine-is-a-perfect-choice-for-global-businesses", "/posts/google-cloud-migration-strategies-and-opportunities-beyond-usual-cloud-efficiencies", "/posts/building-a-modern-digital-enterprise-with-google-cloud-data-fusion"]
            }
          }
          frontmatter: {
            template: {
              eq: "post"
            }
            draft: {
              ne: true
            }
          }
        }
        ) {
          nodes {
            fields {
              slug
              categorySlug
              tagSlugs
            }
            frontmatter {
              title
              date
              category
              description
              socialImage
              tags
            }
          }
        }
    }
  `);

  const iconsMap = icons.nodes.reduce((acc, next) => {
    acc[next.name] = next.publicURL;
    return acc;
  }, {});
  const postsEdges = insightsPosts.nodes.map(node => ({node}));

  return <div className={styles['it-managed-services']}>
    <InnerPagesUpperBanner
      bg={background.nodes[0].publicURL}
      className={styles['it-managed-services-banner']}
      description={[IT_MANAGED_SERVICES_SUBHEADING]}
      descriptionClassName={styles['it-managed-services-banner-description']}
      text={[IT_MANAGED_SERVICES_HEADING]}
      textClassName={styles['it-managed-services-banner-text']}
    />
    <div className={`${styles['section-container']} ${styles.provider}`}>
      <div className={styles.container}>
        <Typography variant="h2" className={styles.heading} align="center" color="secondary">
          {IT_MANAGED_SERVICES_PROVIDER_HEADING}
        </Typography>
        <p className={styles['provider-subheading']}>{IT_MANAGED_SERVICES_PROVIDER_SUBHEADING}</p>
      </div>
    </div>
    <div className={styles['services-list-container-desktop']}>
      <div className={styles['services-columns-container']}>
        {IT_MANAGED_SERVICES_PROVIDER_CONTENT.map(({icon, listItems, title}, index) => {
          return <div className={styles['services-list-column']} key={`services-column-${index}`}>
            <div className={styles['services-list-column-title']}>
              <img src={iconsMap[icon]} />
              {title}
            </div>
            <ul className={styles['services-list']}>
              {listItems.map((text, listItemIndex) => <li key={`services-list-item-${index}-${listItemIndex}`}>
                <span className={styles['services-list-item']}>{text}</span>
              </li>)}
            </ul>
          </div>;
        })}
      </div>
    </div>
    <div className={styles['services-list-container-mobile']}>
      {IT_MANAGED_SERVICES_PROVIDER_CONTENT.map(({icon, listItems, title}, index) => {
        return <div className={styles['card-container-mobile']} key={icon}>
          <ExpandingCard img={iconsMap[icon]}>
            {
              {
                collapsedView: <Typography
                  style={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    width: '100%'
                  }}
                  variant="h4"
                >
                  {title}
                </Typography>,
                expandedView: <div
                  className={'expanding-card-container'}
                  style={{paddingBottom: '6px', paddingTop: 0}}
                >
                  <hr
                    style={{
                      background: '#DFE4ED'
                    }}
                  />
                  <ul className={styles['services-list']}>
                    {listItems.map((text, listItemIndex) => {
                      return <li key={`services-list-mobile-item-${index}-${listItemIndex}`}>
                        <span className={styles['services-list-item']}>{text}</span>
                      </li>
                    })}
                  </ul>
                </div>
              }
            }
          </ExpandingCard>
        </div>;
      })}
    </div>
    <div className={`${styles['section-container']} ${styles.advantages}`}>
      <div className={styles.container}>
        <Advantages content={IT_MANAGED_SERVICES_ADVANTAGES_CONTENT} listClassName={styles['advantages-list']} />
      </div>
    </div>
    <div className={`${styles['section-container']} ${styles.insights}`}>
      <div className={styles.container}>
        <Typography variant="h2" className={styles.heading} align="center" color="secondary">
          {IT_MANAGED_SERVICES_INSIGHTS_HEADING}
        </Typography>
        <Feed edges={postsEdges} />
      </div>
    </div>
    <LeaveMessageBanner />
  </div>;
};

export default ITManagedServices;
